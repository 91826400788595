// --- Обнуление стилей ---
@import "_reset";

// --- Переменные ---
@import "_vars";

// --- Функции ---
@import "_functions";

// --- Импорт шрифтов ---
@import "_fonts";

:root {
	--app-height: 100%;

	--white: #fff;
	--black: #000;
	--gray: #EEEEEE;
	--yellow: #FFF500;

	--bg: url('../img/loading.gif') 50% no-repeat;
}


body {
	font-family: "Lacquer", system-ui;
	font-weight: 400;
	color: var(--black);
	min-height: var(--app-height);
	height: 100%;
	min-width: 100%;
	max-width: 100vw;
	overflow-x: hidden;
	background-color: white;

	&._lock {
		overflow: hidden;
	}
}
@media (prefers-color-scheme: light) {
    body {
        background-color: white;
        color: var(--black);
    }
}
@media (prefers-color-scheme: dark) {
    body {
        background-color: white;
        color: var(--black);
    }
}

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: var(--app-height);
	min-width: 320px;
	height: 100%;
	position: relative;
	overflow: hidden;
	margin: 0 auto;
	background-color: white;
}

.container {
	max-width: $container + px;
	width: 100%;
	margin: 0 auto;
	padding: 0 15px;
}

@import "UI/btn";
@import "UI/navigation";
@import "UI/modal";
@import "UI/notification";
@import "../../components/Screens/Splash/SplashScreen";
@import "../../components/Screens/Home/HomeScreen";
/*
@import "components/footer";
@import "components/header";
@import "blocks/splash";
@import "blocks/main";
@import "blocks/ratings";
@import "blocks/frens";
@import "blocks/shit";
@import "../../node_modules/swiper/swiper";
@import "blocks/projects";
@import "blocks/project";
@import "blocks/tasks";
@import "blocks/error";

 */

