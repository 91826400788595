.main {
    flex: 1 1 auto;
    padding: 30px 0;

    &__container {}

    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__text {
        font-size: to_rem(22);
        text-align: center;
    }

    &__value {
        font-size: to_rem(54);
        line-height: 1;
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 10px 0 20px;

        svg {
            width: 31px;
            height: 31px;
        }
    }

    &__image {
        max-width: 358px;
        width: 100%;
        aspect-ratio: 1 / 1;
        position: relative;
        border-radius: 50%;
        margin-bottom: 20px;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 70%;
            height: 70%;
            object-fit: contain;
        }

        &_invert {
            background: var(--gray);

            img {
                transform: translate(-50%, -50%) scale(-1, 1);
            }
        }
    }

    &__image_yellow {
        background: var(--yellow);
    }

    &__image_gray {
        background: var(--gray);
    }

    &__frens {}

    &__tasks {}

    &__btn {
        font-size: to_rem(28);
        width: 100%;
        max-width: 301px;
        position: relative;
        margin-top: 32px;

        span {
            display: flex;
            align-items: center;
            border-radius: 50px;
            background: var(--yellow);
            color: var(--black);
            font-size: to_rem(22);
            padding: 6px 10px;
            position: absolute;
            top: -16px;
            right: -18px;


            svg {
                width: 12px;
                min-width: 12px;
                height: 12px;
                margin-left: 2px;
            }
        }
    }

    &__widget_section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        flex: 1 1 auto;
    }
}

.frens-main {
    width: 100%;
    margin: 42px 0 32px;

    &__title {
        font-size: to_rem(54);
        margin-bottom: 10px;
        text-align: center;
    }

    &__body {
        padding: 22px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 24px;
        background: var(--yellow);
    }

    &__text {
        font-size: to_rem(22);
        margin-right: 16px;
    }

    &__btn {
        font-size: to_rem(22);
        gap: 3px;

        svg {
            width: 15px;
            min-width: 15px;
            height: 15px;
        }
    }
}

.tasks-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__title {
        font-size: to_rem(32);
        text-align: center;
        margin-bottom: 24px;
        max-width: 300px;
    }

    &__list {
        width: 100%;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &__link {
        font-size: to_rem(17);
        color: var(--black);
        transition: all .5s ease;

        &:hover {
            padding-left: 8px;
        }
    }

    &__value {
        display: flex;
        align-items: center;
        border-radius: 50px;
        background: var(--yellow);
        padding: 6px 16px;

        svg {
            width: 12px;
            min-width: 12px;
            height: 12px;
            margin-left: 2px;
        }
    }
}

.tasks {
    padding: 28px 0;
    width: 100%;

    &__container {}

    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        font-size: to_rem(32);
        text-align: center;
        position: relative;
        margin-bottom: 24px;

        span {
            position: absolute;
            left: 105%;
            top: 50%;
            transform: translate(0, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px 8px;
            background: var(--yellow);
            border-radius: 50px;
        }

        svg {
            width: 12px;
            height: 12px;
        }
    }

    &__list {
        width: 100%;
    }

    &__item {

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        transition: all .5s ease;

        @media (any-hover: hover) {
            &:hover {
                padding: 0 10px;
            }
        }

        &.hover {
            padding: 0 10px;
        }

        &_done {
            .tasks__name {
                text-decoration: line-through;
            }

            .tasks__value {
                background: var(--gray);

                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }

    &__image, &__icon {
        width: 45px;
        min-width: 45px;
        height: 45px;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 12px;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;

        }
    }
    &__icon {
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            width: 50%;
            height: 50%;
            path {
                fill: white;
            }
        }
    }


    &__name {
        font-size: to_rem(18);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        flex: 1 1 auto;
        margin-right: 12px;
        color: var(--black);
    }

    &__value {
        font-size: to_rem(22);
        padding: 6px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        border-radius: 50px;
        background: var(--yellow);
        color: var(--black);
        min-width: 80px;
        min-height: 40px;

        svg {
            width: 12px;
            height: 12px;
        }
    }
}

/* Task Poup Animations */

.task-list-enter {
    transform: translateX(0);
}
.task-list-enter-active {
    transform: translateX(-100%);
    transition: transform 300ms;
}
.task-list-exit {
    transform: translateX(-100%);
}
.task-list-exit-active {
    transform: translateX(0);
    transition: transform 300ms;
}

.popup-enter {
    transform: translateX(100%);
}
.popup-enter-active {
    transform: translateX(0);
    transition: transform 300ms;
}
.popup-exit {
    transform: translateX(0);
}
.popup-exit-active {
    transform: translateX(100%);
    transition: transform 300ms;
}


#slider {
    position: absolute;
    width: 100px;
    height: 100px;
    background: blue;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
}

.slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
}

@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(-100%); }
}

.popup-column-wrapper {
    display: flex;

}